import React from 'react'

interface TitleProps {
  tag: keyof JSX.IntrinsicElements
  title: string
  underline?: boolean
  underlineColor?: string
}

const Title: React.FC<TitleProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  tag,
  title,
  ...rest
}) => {
  const Tag = tag
  return <Tag {...rest}>{title}</Tag>
}

export default Title

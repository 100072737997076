import { SiteConfig } from './types'
import * as images from './images'
import { FaRoad } from 'react-icons/fa'

export const CONFIG: SiteConfig = {
  services: [
    {
      title: 'Building Sites',
      description: [
        'We make fun slides for you and your team to slide down and enjoy the sun.',
        'If you like ladders and chutes you definitely need to try ladderchutes as we are like a building site come theme park!',
      ],
      icon: FaRoad,
      gallery: images.buildingSite,
    },
    {
      title: 'Home Slides',
      description: [
        'We make fun slides for you and your team to slide down and enjoy the sun.',
        'If you like ladders and chutes you definitely need to try ladderchutes as we are like a building site come theme park!',
      ],
      icon: FaRoad,
      gallery: images.home,
    },
  ],
  siteInfo: {
    businessName: 'Ladder Chutes',
    address: ['1 Ladder Chute', 'Knebworth', 'Stevenage', 'England', 'N4T 0A7'],
    phone: '07939888366',
    social: {
      instagram: 'https://www.instagram.com/ladderchutes',
    },
  },
}

import React, { useState } from 'react'
import './Modal.scss'

interface ModalProps {
  children: React.ReactNode
  title: string
  label: string
  useButton?: boolean
}

const Modal: React.FC<ModalProps & React.HTMLAttributes<HTMLOrSVGElement>> = ({
  children,
  title,
  label,
  useButton,
}) => {
  const [open, setOpen] = useState<boolean>(false)
  return (
    <>
      {useButton ? (
        <button
          onClick={(event) => {
            event.preventDefault()
            setOpen(true)
          }}
        >
          {label}
        </button>
      ) : (
        <a
          href="#"
          title={`Open ${title} modal`}
          className="modal__open"
          onClick={(event) => {
            event.preventDefault()
            setOpen(true)
          }}
        >
          {label}
        </a>
      )}
      <section className="modal__wrapper" aria-hidden={open ? 'false' : 'true'}>
        <div className="modal__container">
          <div className="modal__header">
            <h3>{title}</h3>
          </div>
          <div className="modal__content">{children}</div>
          <button
            onClick={() => setOpen(false)}
            className="modal__button--close"
          >
            Close
          </button>
        </div>
      </section>
    </>
  )
}

export default Modal

import GalleryProps from '../types'

import image1 from '../../../assets/images/building-site/building-site.jpeg'

import image1thumb from '../../../assets/images/building-site/75x75/building-site.jpeg'

import image1gallery from '../../../assets/images/building-site/350x250/building-site.jpeg'

import image1Large from '../../../assets/images/building-site/700x500/building-site.jpeg'

export const buildingSite: GalleryProps = {
  full: [image1],
  thumb: [image1thumb],
  active: [image1gallery],
  large: [image1Large],
}

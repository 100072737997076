import React from 'react'
import SocialSeparator from '../../components/social-separator'
import KeyFeatures from '../../components/key-features'
import About from '../../components/about'
import Header from '../../components/header'

const Home: React.FC = () => {
  return (
    <>
      <Header />
      <About />
      <SocialSeparator />
      <KeyFeatures />
    </>
  )
}

export default Home

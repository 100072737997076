import React from 'react'
import Modal from '../base/Modal'
import { CONFIG } from '../../config'
import Ladder from '../../assets/images/full-light-250x83.png'

const Footer: React.FC = () => (
  <footer className="footer__wrapper">
    <div className="footer__tile">
      <div style={{ margin: '1rem 0px' }}>
        <img width={225} src={Ladder} />
      </div>
      <address>
        <a href={`tel:${CONFIG.siteInfo.phone}`} title="Call We Interlock">
          {CONFIG.siteInfo.phone}
        </a>
      </address>
    </div>
    <div className="footer__links">
      <Modal title="Privacy Policy" label="Privacy Policy">
        <p>We do not store your data or sell it to third parties.</p>
      </Modal>
      <Modal title="Terms of Use" label="Terms of Use">
        <p>
          As with all websites, this site is provided as is and we do not accept
          liability for damage to your machine while browsing it.
        </p>
        <p>
          By contacting us you are agreeing that we have permission to reply to
          you.
        </p>
      </Modal>
    </div>
  </footer>
)

export default Footer

import React, { useEffect } from 'react'
import Navbar from './components/navbar'
import logo from './assets/images/icon.svg'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Home from './pages/Home'
import ContactForm from './components/contact-form'
import Footer from './components/footer'

const App: React.FC = () => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--vh',
      `${window.innerHeight * 0.01}px`
    )
  }, [])

  return (
    <div className="app-wrapper">
      <Router>
        <Navbar logoSrc={logo} logoAlt="React logo" />
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
        <ContactForm />
      </Router>
      <Footer />
    </div>
  )
}

export default App

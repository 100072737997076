import React from 'react'
import { CONFIG } from '../../config'
import { FaInstagram } from 'react-icons/fa'

const SocialSeparator: React.FC = () => {
  return (
    <section id="social-separator" className="social-separator-section">
      <div className="social-icons__wrapper">
        {CONFIG.siteInfo.social?.instagram && (
          <a href={CONFIG.siteInfo?.social.instagram}>
            <FaInstagram />
          </a>
        )}
      </div>
    </section>
  )
}

export default SocialSeparator

import React from 'react'
import Hero from '../../components/base/Hero'
import background from '../../assets/images/home-header-bg.png'
import logo from '../../assets/images/full-light-500x167.png'

const Header: React.FC = () => {
  return (
    <Hero backgroundImage={background}>
      <div className="hero-content__wrapper">
        <h1 style={{ color: 'white' }}>
          <img src={logo} alt="Ladder Chutes logo" />
        </h1>
      </div>
    </Hero>
  )
}

export default Header

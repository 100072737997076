import React from 'react'
import Title from '../base/Title'

const About: React.FC = () => {
  return (
    <section id="about" className="about-us">
      <div id="information" className="info-section">
        <Title tag="h2" title="What are Ladder Chutes?" />
        <div className="text-box">
          <p>
            Ladder chutes are a multi-purpose refuse dispersal system designed
            with safety and speed in mind the chutes can safely remove rubbish
            and debris from hight in safe and controlled manor using the forces
            of gravity.
          </p>
          <p>
            They have been designed with the building trade in mind but lend
            themselves to many other applications when removing material from
            height.
          </p>
          <p>
            The chutes can be fitted to any ladder via its locking system and
            attached to a structure or working platform of your choice in a
            matter of minutes.
          </p>
          <p>
            The systems design will let the user have the ability too redirect
            the chutes from area to area or skip to skip when the area becomes
            full.
          </p>
          <p>
            The chutes have the added ability of being extendable when
            destination point is not directly below you work site.
          </p>
          <p>
            Once the work has been completed all 6 chutes and the hopper fit
            neatly inside one another saving space in your vehicle or workshop
            they can also be strapped securely to your ladders on the roof rack
            of your vehicle.
          </p>
        </div>
      </div>
    </section>
  )
}

export default About

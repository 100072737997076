import React, { useState, useEffect } from 'react'
import { LinkInterface } from './types'
import { links } from './data/navlinks'
import { HashLink as Link } from 'react-router-hash-link'
import './style.scss'

interface NavbarProps {
  logoSrc: string
  logoAlt: string
}

const Navbar: React.FC<
  NavbarProps & React.HTMLAttributes<HTMLOrSVGElement>
> = ({ logoSrc, logoAlt, ...rest }) => {
  const [visible, setVisible] = useState(true)
  const [previousScroll, setPreviousScroll] = useState(0)

  const handleScroll = () => {
    const currentScroll = window.pageYOffset
    const isScrollingDown = currentScroll > previousScroll
    if (isScrollingDown && currentScroll - previousScroll > 100) {
      setPreviousScroll(currentScroll)
      return setVisible(false)
    } else if (previousScroll - currentScroll > 100) {
      setPreviousScroll(currentScroll)
      return setVisible(true)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [previousScroll, visible, handleScroll])

  return (
    <nav
      className="main-navigation"
      style={{
        transition: '0.3s ease',
        padding: `${visible ? '0.1rem 0.6rem' : '1.2rem 0.9rem'}`,
        backgroundColor:
          window.pageYOffset > 0 ? 'var(--black)' : 'transparent',
      }}
      {...rest}
    >
      <div className="navbar-logo">
        <img src={logoSrc} alt={logoAlt} height={30} width={30} />
      </div>
      <div className="navbar-links">
        <ul>
          {links.map((link: LinkInterface, i) => (
            <li key={i}>
              <Link to={{ hash: link.href }} title={link.title}>
                {link.label}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Navbar

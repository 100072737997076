import React from 'react'
import './Hero.scss'
interface HeroProps {
  backgroundImage: string
  children?: React.ReactNode
}

const Hero: React.FC<HeroProps> = ({ backgroundImage, children }) => {
  const style = {
    backgroundImage: `url('${backgroundImage}')`,
  }
  return (
    <section className="hero-section gradient">
      <span style={style}></span>
      {children}
    </section>
  )
}

export default Hero

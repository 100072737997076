import React, { useState, useEffect } from 'react'
import './style.scss'
import useField from './hooks/useField'

interface ApiResponse {
  status: number
  message: string
  error: boolean
}

interface Notification {
  type: string
  message: string
}

const ContactForm: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showGotcha, setShowGotcha] = useState<boolean>(false)
  const [notification, setNotification] = useState<Notification | null>(null)
  const [showButton, setShowButton] = useState<boolean>(true)

  const contactForm = React.useRef<HTMLFormElement>(null)

  const name = useField('text')
  const email = useField('email')
  const subject = useField('text')
  const message = useField('textarea')

  useEffect(() => {
    watchForFooter()
  }, [])

  const sendForm: VoidFunction = async () => {
    setLoading(true)
    if (!contactForm.current) return setLoading(false)

    const allInputs = [
      ...Array.from(contactForm.current.querySelectorAll('input')),
      ...Array.from(contactForm.current.querySelectorAll('textarea')),
    ]

    interface RequestBody {
      [key: string]: string
    }

    const requestBody: RequestBody = allInputs.reduce((output, current) => {
      output[current.name] = current.value
      return output
    }, {} as RequestBody)

    try {
      const response = await fetch('/api/mailer', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      })
      const { status } = (await response.json()) as ApiResponse

      if (status === 500) {
        setLoading(false)
        setNotification({
          type: 'error',
          message: 'There has been an error our server, try again later',
        })
        return
      }

      if (status === 400) {
        setLoading(false)
        setNotification({
          type: 'error',
          message: 'You seem to have filled the form incorrectly.',
        })
        return
      }

      if (status === 200) {
        setLoading(false)
        setNotification({
          type: 'success',
          message: 'Your message has been sent. Someone will respond ASAP.',
        })
        return
      }
    } catch (error) {
      setLoading(false)
      setNotification({
        type: 'error',
        message:
          'There seems to be an error with our server at the moment. Please try again',
      })
    }
  }

  const formSubmissionHandler: React.FormEventHandler = (event) => {
    event.preventDefault()
    setLoading(true)

    if (!contactForm.current) return

    const allInputs = [
      ...Array.from(contactForm.current.querySelectorAll('input')),
      ...Array.from(contactForm.current.querySelectorAll('textarea')),
    ]

    const errors = allInputs.filter(
      (input) =>
        input.getAttribute('data-error') === 'true' || input.value.length < 3
    )

    if (errors.length > 0) {
      setLoading(false)

      errors.forEach((err) =>
        err?.parentElement?.setAttribute('data-error', 'true')
      )

      setNotification({
        type: 'error',
        message: 'You seem to have filled the form incorrectly.',
      })
      return
    } else {
      return setTimeout(() => {
        setLoading(false)
        setShowGotcha(true)
      }, 1000)
    }
  }

  const handleFormToggle = () => {
    setOpen(!open)
  }

  const NotificationCard: React.FC = () => {
    return notification ? (
      <section className="contact-form__notification">
        <div className="contact-form__notification--card">
          <p>{notification.message}</p>
          <button onClick={() => setNotification(null)}>Close</button>
        </div>
      </section>
    ) : null
  }

  interface GotchaProps {
    onSubmit: () => void
    formRef: React.RefObject<HTMLFormElement>
    setLoading?: React.Dispatch<React.SetStateAction<boolean>>
    showGotcha?: React.Dispatch<React.SetStateAction<boolean>>
  }

  const Gotcha: React.FC<GotchaProps> = ({
    onSubmit,
    formRef,
    setLoading,
    showGotcha,
  }) => {
    const [gotcha, setGotcha] = useState<keyof typeof numbers>('zero')
    const [gotchaAnswer, setUserAnswer] = useState<string>('')
    const numbers = {
      zero: '0',
      one: '1',
      two: '2',
      three: '3',
      four: '4',
      five: '5',
      six: '6',
      seven: '7',
      eight: '8',
      nine: '9',
      ten: '10',
    }

    const getGotchaNumber = () =>
      Object.keys(numbers)[
        Math.floor(Math.random() * 10)
      ] as keyof typeof numbers

    const handleSubmission: React.FormEventHandler = () => {
      if (setLoading) {
        setLoading(true)
      }
      console.log(numbers[gotcha])
      console.log(gotchaAnswer)
      console.log(formRef.current)
      if (showGotcha) {
        showGotcha(false)
      }
      onSubmit()
    }

    useEffect(() => {
      const gotchaToUse = getGotchaNumber()
      return setGotcha(gotchaToUse)
    }, [])

    return showGotcha ? (
      <section className="contact-form__gotcha">
        <div className="contact-form__gotcha--card">
          <p>
            Please enter the following number: <span>{gotcha}</span>
          </p>
          <div
            className="contact-form__input--wrapper"
            data-error={numbers[gotcha] !== gotchaAnswer ? 'true' : 'false'}
            data-valid={numbers[gotcha] === gotchaAnswer ? 'true' : 'false'}
          >
            <span>Enter {gotcha} as a number</span>
            <input
              type="number"
              required
              value={gotchaAnswer}
              onChange={(event) => setUserAnswer(event.currentTarget.value)}
            />
          </div>
          <div>
            <button onClick={() => setShowGotcha(false)}>Close</button>
            <button onClick={handleSubmission}>Submit</button>
          </div>
        </div>
      </section>
    ) : null
  }

  const watchForFooter = () => {
    const config = {
      root: null,
      rootMargin: '0px',
      threshold: 0.1,
    }
    const footer = document.querySelector('footer') as HTMLElement
    const callback = (entries: IntersectionObserverEntry[]) => {
      for (const entry of entries) {
        if (entry.isIntersecting) {
          setShowButton(false)
        } else {
          setShowButton(true)
        }
      }
    }
    const observer = new IntersectionObserver(callback, config)
    observer.observe(footer)
  }

  return (
    <>
      <button
        className={`contact-form__open${!showButton ? ' hide' : ''}`}
        aria-label="Contact us"
        onClick={handleFormToggle}
        aria-hidden={open || !showButton ? 'true' : 'false'}
      >
        Contact
      </button>
      <section
        className={`contact-form__wrapper${loading ? ' loading' : ''}`}
        aria-hidden={open ? 'false' : 'true'}
      >
        <div className="contact-form__form--card">
          <button id="close-contact-form" onClick={handleFormToggle}></button>
          <h2>Contact Us</h2>
          <form ref={contactForm} onSubmit={formSubmissionHandler}>
            <div className="contact-form__fields">
              <div className="contact-form__input--row">
                <div className="contact-form__input">
                  <label>Name</label>
                  <div className="contact-form__input--wrapper">
                    <span>Min 3 characters. Only letters.</span>
                    <input inputMode="text" name="name" {...name} />
                  </div>
                </div>
                <div className="contact-form__input">
                  <label>Email</label>
                  <div className="contact-form__input--wrapper">
                    <span>That email seems incorrect.</span>
                    <input inputMode="email" name="email" {...email} />
                  </div>
                </div>
              </div>
              <div className="contact-form__input--row">
                <div className="contact-form__input">
                  <label>Subject</label>
                  <div className="contact-form__input--wrapper">
                    <span>Min 3 characters. Only letters.</span>
                    <input name="subject" inputMode="text" {...subject} />
                  </div>
                </div>
              </div>
              <div className="contact-form__input--row">
                <div className="contact-form__text-area">
                  <label>Message</label>
                  <div className="contact-form__text-area--wrapper">
                    <span>Min 3 characters.</span>
                    <textarea
                      inputMode="text"
                      name="message"
                      rows={6}
                      {...message}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <button type="submit">Submit</button>
          </form>
          {notification && <NotificationCard />}
          {showGotcha && (
            <Gotcha
              formRef={contactForm}
              onSubmit={sendForm}
              setLoading={setLoading}
              showGotcha={setShowGotcha}
            />
          )}
        </div>
      </section>
    </>
  )
}

export default ContactForm

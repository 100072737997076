import GalleryProps from '../types'

import image1 from '../../../assets/images/home/home-slide.jpeg'

import image1thumb from '../../../assets/images/home/75x75/home-slide.jpeg'

import image1gallery from '../../../assets/images/home/350x250/home-slide.jpeg'

import image1Large from '../../../assets/images/home/700x500/home-slide.jpeg'

export const home: GalleryProps = {
  full: [image1],
  thumb: [image1thumb],
  active: [image1gallery],
  large: [image1Large],
}

import { LinkInterface } from '../types'

export const links: LinkInterface[] = [
  { title: 'About Ladder Chutes', href: 'about', label: 'About' },
  {
    title: 'Key features of Ladder Chutes',
    href: 'features',
    label: 'Features',
  },
]

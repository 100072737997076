import { useState } from 'react'

interface FieldOutput {
  type: string
  value: string
  onChange: (a: React.ChangeEvent) => void
  onBlur: (a: React.FocusEvent) => void
}

const useField = (type: string): FieldOutput => {
  const [value, setValue] = useState<string>('')

  const validate = (value: string): boolean => {
    switch (type) {
      case 'text':
        return !/([\d]|[^\w \s])+/gi.test(value)
      case 'number':
        return /(\d+)+/gi.test(value)
      case 'email':
        return /^[^@\s]+@[^@\s.]+\.[^@.\s]+(\.{1}[^@.\s]+)?$/.test(value)
      default:
        return true
    }
  }

  const onBlur: React.FocusEventHandler = (
    event: React.FocusEvent<HTMLInputElement>
  ) => {
    const isValid = validate(event.currentTarget.value)
    if (!isValid || event.currentTarget.value.length < 3) {
      event.currentTarget.parentElement?.setAttribute('data-error', 'true')
      event.currentTarget?.setAttribute('data-error', 'true')
    } else {
      event.currentTarget.parentElement?.setAttribute('data-error', 'false')
      event.currentTarget?.setAttribute('data-error', 'false')
    }
  }

  const onChange: React.ChangeEventHandler = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setValue(event.currentTarget.value)
  }
  return {
    type,
    value,
    onChange,
    onBlur,
  }
}

export default useField

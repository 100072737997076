import React from 'react'
import Title from '../base/Title'
import Modal from '../base/Modal'

const KeyFeatures: React.FC = () => {
  return (
    <section className="key-features" id="features">
      <Title tag="h2" title="Key Features" />
      <ul>
        <li>Enclose hopper for loading material safely.</li>
        <li>
          6 inch safety returns to stop rubbish spilling from the sides as
          rubbish descends.
        </li>
        <li>
          Tether points on the bottom chute to secure to your bottom structure.
        </li>
        <li>The chutes fit and lock together to become one when in use.</li>
        <li>Retro fit to any ladder setup.</li>
        <li>
          The chutes can be used in any configuration by adding or taking away
          chutes to get to your desired distance.
        </li>
        <li>
          When packed away the chutes slide into one another for easy storage.
        </li>
        <li>Machined carry handles </li>
      </ul>
      <Modal
        title="6 Chutes and 1 Hopper"
        label="Product Details"
        useButton={true}
      >
        <p>
          The chutes are each 4 foot in length and 12 inches wide with the wall
          6 inches and the safety returns also at 6 inches.
        </p>
        <p>
          The chutes are made from aluminium or polyurethane, so it is resistant
          to heat all hardware attached to the chutes are aluminium to avoid
          rusting.
        </p>
        <p>
          The hardware consists of a rear bracket to secure the chute over
          ladder runs with a chain a carabiner locking system which runs around
          the bracket and ladder run.
        </p>
        <p>
          The last chute in the system has a tether point on both sides to allow
          the chute to be securely anchored to the ground or destination of
          choice.
        </p>
        <p>
          The hopper is a fully enclosed chute which enables safe loading from
          you load point and also dampens bounce and movement of material to
          allow the safe flow of rubbish to the destination point.
        </p>
      </Modal>
    </section>
  )
}

export default KeyFeatures
